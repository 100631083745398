import React from "react";

export function SearchResult(props) {
  return (
    <tr>
      <td>{props.record.surname}</td>
      <td>{props.record.givenFirst}</td>
      <td>{props.record.givenMiddle}</td>
      <td>{props.record.locationId}</td>
    </tr>
  );
}
