import {ApolloClient} from "apollo-boost";

import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";

function getCookie(name) {
  var re = new RegExp(name + "=([^;]+)");
  var value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : null;
}
const csrfCookie = getCookie("csrftoken");

export const CLIENT = new ApolloClient({
  link: createHttpLink({
    uri: "https://genealogyindex.herokuapp.com/graphql",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfCookie
    }
  }),
  cache: new InMemoryCache()
});

// export const CLIENT = new ApolloClient({
//   link: createHttpLink({
//     uri: "http://127.0.0.1:8000/graphql",
//     credentials: "include",
//     headers: {
//       "X-CSRFToken": csrfCookie
//     }
//   }),
//   cache: new InMemoryCache()
// });

// export {CLIENT};
