import React from "react";

import {BrowserRouter as Router, Route} from "react-router-dom";

// import ApolloClient from "apollo-boost";
import {ApolloProvider} from "@apollo/react-hooks";

import {Home} from "./pages/Home";
import {Login} from "./pages/Login";
import {Search} from "./pages/Search";

// const client = new ApolloClient({
//   // uri: "https://genealogyindex.herokuapp.com"
//   uri: "http://127.0.0.1:8000"
// });

import {CLIENT} from "./MyApolloClient";

function App() {
  return (
    <ApolloProvider client={CLIENT}>
      <Router>
        <Route path="/login" component={Login} />
        <Route path="/search/:query" component={Search} />
        <Route path="/search" exact component={Search} />
        <Route path="/" exact component={Home} />
      </Router>
    </ApolloProvider>
  );
}

export default App;
