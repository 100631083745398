import React from "react";

// import {Link} from "react-router-dom";

export function Header() {
  return (
    <div id="header">
      <div className="flex bg-white border-b border-gray-200 fixed top-0 inset-x-0 z-100 h-20 items-center border-b-2 border-gray-500">
        <div className="w-full max-w-6xl relative mx-auto px-6">
          <div className="flex items-center -mx-6">
            <div className="lg:w-1/3 xl:w-2/5 pl-6 pr-6 lg:pr-8">
              <div className="flex items-center">
                <a href="/" className="block lg:mr-4 text-4xl text-gray-800">
                  Genealogy Index
                </a>
              </div>
            </div>

            <div className="lg:w-2/3 xl:w-3/5 pl-6 pr-6">
              {/* <div className="text-xl"> */}
              <div className="text-right text-xl text-gray-800">
                East Bloomfield Historical Society
              </div>
              {/* <span
                      style={{
                        position: `relative`,
                        display: `inline-block`,
                        direction: `ltr`
                      }}
                    >
                      <input
                        id="docsearch"
                        className="transition focus:outline-0 border border-transparent focus:bg-white focus:border-gray-300 placeholder-gray-600 rounded-lg bg-gray-200 py-2 pr-4 pl-10 block w-full appearance-none leading-normal ds-input"
                        type="text"
                        placeholder='Search the docs (Press "/" to focus)'
                        role="combobox"
                        aria-autocomplete="list"
                        aria-expanded="false"
                        aria-label="search input"
                        aria-owns="algolia-autocomplete-listbox-0"
                        dir="auto"
                        style={{position: `relative`, verticalAlign: `top`}}
                      />
                      <pre
                        aria-hidden="true"
                        style={{
                          position: `absolute`,
                          visibility: `hidden`,
                          whiteSpace: `pre`,
                          fontFamily: `-apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
                          fontSize: `16px`,
                          fontStyle: `normal`,
                          fontVariant: `normal`,
                          fontWeight: `400`,
                          wordSpacing: `0px`,
                          letterSpacing: `normal`,
                          textIndent: `0px`,
                          textRendering: `auto`,
                          textTransform: `none`
                        }}
                      ></pre>
                      <span
                        className="ds-dropdown-menu"
                        role="listbox"
                        id="algolia-autocomplete-listbox-0"
                        style={{
                          position: `absolute`,
                          top: `100%`,
                          zIndex: `100`,
                          display: `none`,
                          left: `0px`,
                          right: `auto`
                        }}
                      >
                        <div className="ds-dataset-1"></div>
                      </span>
                    </span>
                    <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center"></div> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
