import React, {useState} from "react";

import {withRouter} from "react-router-dom";

function SearchInputWithoutRouter(props) {
  const [query, setQuery] = useState(props.query ? props.query : "");

  function handleSearch(event) {
    event.preventDefault();
    props.history.push(`/search/${query}`);
  }
  function queryChange(event) {
    setQuery(event.target.value);
  }

  return (
    <form onSubmit={handleSearch}>
      <input
        className="text-4xl p-4 rounded"
        placeholder="Enter Search Term"
        value={query}
        onChange={queryChange}
        autoFocus
      />
    </form>
  );
}

const SearchInput = withRouter(SearchInputWithoutRouter);

export {SearchInput};
