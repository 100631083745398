import React from "react";

import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

import {SearchResult} from "./SearchResult";

const GET_SEARCH_RESULTS = gql`
  query UserSettingsQuery($searchTerm: String!) {
    matchingRecords(searchTerm: $searchTerm) {
      id
      givenFirst
      givenMiddle
      surname
      locationId
    }
  }
`;

export function SearchResults(props) {
  const {loading, error, data} = useQuery(GET_SEARCH_RESULTS, {
    variables: {searchTerm: props.query}
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error: {error}</p>;
  return (
    <div>
      <h1>{data.matchingRecords.length} Matches Found</h1>
      <table className="w-full text-left table-collapse">
        <thead>
          <tr>
            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-100">
              Surname
            </th>
            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-100">
              Given Name
            </th>
            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-100">
              Middle
            </th>
            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-100">
              Location
            </th>
          </tr>
        </thead>
        <tbody>
          {data.matchingRecords.map(record => (
            <SearchResult record={record} key={record.id} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
