import React, {Fragment} from "react";

import {Link} from "react-router-dom";

import "../css/tailwind.css";

import {Header} from "../Components/Header";
import {SearchInput} from "../Components/SearchInput";
import {SearchResults} from "../Components/SearchResults";

export function Search(props) {
  const query = props.match.params.query;

  return (
    <Fragment>
      <Header />
      <br />
      <br />
      <br />
      <br />
      <div className="max-w-6xl w-full mx-auto px-6">
        <div className="lg:flex -mx-6 pl-6">
          <div>
            <h1 className="text-6xl text-gray-800 font-semibold">Search</h1>

            <SearchInput query={query} />
            <p className="text-3xl">
              <Link
                role="button"
                to="/login"
                className="btn btn-lg btn-default"
              >
                Login
              </Link>
            </p>
            {query && (
              <Fragment>
                <h1 className="text-6xl text-gray-800 font-semibold">
                  Search Results
                </h1>
                <SearchResults query={query} />
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
