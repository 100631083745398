import React, {Fragment} from "react";

import "../css/tailwind.css";

import {Link} from "react-router-dom";

import {Header} from "../Components/Header";

export function Home() {
  return (
    <Fragment>
      <Header />
      <div className="w-full mx-auto px-6 min-h-full">
        <br />
        <br />
        <br />
        <br />
        <div className="max-w-6xl w-full mx-auto px-6">
          <div className="lg:flex -mx-6 pl-6">
            <div>
              <h1 className="text-6xl text-gray-800 font-semibold">
                Getting Started
              </h1>
              <p className="text-3xl">
                Welcome to the EBHS Genealogy Index. On this website you can
                search the index to find out some of the names that are
                contained in the collection of records at the East Bloomfield
                Historical Society.
              </p>

              <br />

              <p className="text-3xl">
                Presently there are 91,871 records in the database.
              </p>

              <br />

              <Link role="button" to="/search">
                <button className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded text-3xl">
                  Start Searching »
                </button>
              </Link>

              {/* <ul className="mt-4 list-none">
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">
                    Getting Tailwind up and running in your project
                  </span>
                </li>{" "}
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">
                    Building complex application layouts with flexbox
                  </span>
                </li>
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">
                    Using Tailwind's color, size, and weight utilities to create
                    hierarchy
                  </span>
                </li>{" "}
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">
                    Creating depth in your designs using shadows and layers
                  </span>
                </li>{" "}
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">
                    Flexible positioning techniques for building components that
                    are adaptive instead of static and rigid
                  </span>
                </li>{" "}
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">
                    Implementing custom form styles
                  </span>
                </li>{" "}
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">Extracting reusable components</span>
                </li>{" "}
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">Working with plugins</span>
                </li>{" "}
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">
                    Customizing Tailwind to meet the needs of your design
                  </span>
                </li>{" "}
                <li className="mt-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 mt-px mr-3 flex-shrink-0"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      className="text-green-200 fill-current"
                    ></circle>
                    <path
                      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                      className="text-green-600 fill-current"
                    ></path>
                  </svg>{" "}
                  <span className="-mt-px">
                    Augmenting Tailwind with JavaScript to make your designs
                    interactive
                  </span>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
