import React from "react";

// import { Link } from "react-router-dom";

import "../css/tailwind.css";

import {Header} from "../Components/Header";

export function Login() {
  return (
    <div>
      <Header />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="flex justify-center">
        <form className="w-full max-w-xl bg-white shadow-md rounded p-12 mb-12">
          <h1 className="text-5xl text-gray-800 font-semibold">
            EBHS Staff Login
          </h1>
          <br />
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-2/5">
              <label
                className="block text-gray-500 text-3xl font-bold md:text-right mb-1 md:mb-0 pr-4"
                htmlFor="inline-full-name"
              >
                User Name
              </label>
            </div>
            <div className="md:w-3/5">
              <input
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 text-3xl leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-full-name"
                type="text"
                // value="Jane Doe"
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-2/5">
              <label
                className="block text-gray-500 text-3xl font-bold md:text-right mb-1 md:mb-0 pr-4"
                htmlFor="inline-username"
              >
                Password
              </label>
            </div>
            <div className="md:w-3/5">
              <input
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 text-3xl leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-username"
                type="password"
                // placeholder="******************"
              />
            </div>
          </div>

          <div className="md:flex md:items-center">
            <div className="md:w-2/5"></div>
            <div className="md:w-3/5">
              <button
                className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded text-3xl"
                type="button"
              >
                &nbsp; Log In &nbsp;
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
